import React, { useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import * as yup from "yup";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { documentApprovalStatus } from "../../../../AggregatorOnboardingReview/util";
import Alert from "../../../../../NewComponents/TypedComponents/Alert/Alert.component";

const schema = yup.object().shape({
  comment: yup.string().trim().required("Please enter your comment"),
});

const FunderPeopleDocumentsApprovalModal = ({ data, refetchData }: any) => {
  const [isTakingDocumentAction, setIsTakingDocumentAction] = useState(false);

  const match = useRouteMatch();

  const dismissModalRef = useRef<HTMLButtonElement>(null);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  const onSubmit = (formData: any, actionType: any) => {
    if (actionType === "approve") {
      handleApprove(formData?.comment);
    } else if (actionType === "decline") {
      handleDecline(formData?.comment);
    }
  };

  const handleApprove = async (comment: string) => {
    setIsTakingDocumentAction(true);
    try {
      const payload = {
        funder_id: Number(data?.data?.data?.funderId),
        funder_person_id: data?.data?.data?.funderPersonId,
        funder_people_document_type: data?.data?.documentType,
        approval_status: documentApprovalStatus?.APPROVED,
        comment: comment,
      };
      const response = await putData(
        `${ajaxEndpoints.FUNDER_PEOPLE_DOCUMENT_APPROVAL}`,
        payload
      );

      if (response?.data?.status_code === 200) {
        setSuccess(response?.data?.message);
        refetchData();
        dismissModalRef.current && dismissModalRef.current.click();
        handleCloseModal();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
    }
    setIsTakingDocumentAction(false);
  };

  const handleDecline = async (comment: string) => {
    setIsTakingDocumentAction(true);

    try {
      const payload = {
        funder_id: Number(data?.data?.data?.funderId),
        funder_person_id: data?.data?.data?.funderPersonId,
        funder_people_document_type: data?.data?.documentType,
        approval_status: documentApprovalStatus?.DECLINED,
        comment: comment,
      };

      const response = await putData(
        `${ajaxEndpoints.FUNDER_PEOPLE_DOCUMENT_APPROVAL}`,
        payload
      );
      if (response?.data?.status_code === 200) {
        setSuccess(response?.data?.message);
        refetchData();
        dismissModalRef.current && dismissModalRef.current.click();
        handleCloseModal();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
    }
    setIsTakingDocumentAction(false);
  };

  const handleCloseModal = () => {
    const modalClassList = document.getElementById(
      "funderPeopleDocumentsApprovalModal"
    );

    if (!modalClassList?.classList.contains("show")) {
      setError(null);
      setSuccess(null);
      reset();
    }
  };

  return (
    <div
      className="modal fade"
      id="funderPeopleDocumentsApprovalModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="funderPeopleDocumentsApprovalModalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${
          data?.actionType === "cancel" && "modal-sm"
        } modal-dialog-centered`}
        role="document"
      >
        <div className="modal-content">
          {success && <Alert message={success} type="success" />}
          {error && <Alert message={error} />}
          {(() => {
            switch (data?.actionType) {
              case "approve":
                return (
                  <React.Fragment>
                    <div className="modal-header">
                      <h5 className="page-subtitle">Approve Document</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                        ref={dismissModalRef}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <React.Fragment>
                        <p className="text-justify">
                          Please add a comment to complete this process.
                        </p>
                        <form
                          onSubmit={handleSubmit((data) =>
                            onSubmit(data, "approve")
                          )}
                        >
                          <div className="row">
                            <div className="col-12">
                              <CustomTextArea
                                maxLength={256}
                                reference={register}
                                placeholder="Enter comment here..."
                                label="Admin Comment"
                                name="comment"
                                showRequiredIcon={true}
                                errors={errors?.comment}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                              >
                                {isTakingDocumentAction
                                  ? "Approving..."
                                  : "Confirm Approval"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </React.Fragment>
                    </div>
                  </React.Fragment>
                );
              case "decline":
                return (
                  <React.Fragment>
                    <div className="modal-header">
                      <h5 className="page-subtitle">Decline Document</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                        ref={dismissModalRef}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className="text-justify">
                        Please add a comment to complete this process.
                      </p>
                      <form
                        onSubmit={handleSubmit((data) =>
                          onSubmit(data, "decline")
                        )}
                      >
                        <div className="row">
                          <div className="col-12">
                            <CustomTextArea
                              maxLength={256}
                              reference={register}
                              placeholder="Enter comment here..."
                              label="Admin Comment"
                              name="comment"
                              showRequiredIcon={true}
                              errors={errors?.comment}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-end flex-wrap">
                            <button
                              className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                              type="button"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                              type="submit"
                            >
                              {isTakingDocumentAction
                                ? "Declining..."
                                : "Confirm Decline"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default FunderPeopleDocumentsApprovalModal;
