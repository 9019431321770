import { Fragment, useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import useAnAggregatorOnboardingParameters from "../../../custom-hooks/useAnAggregatorOnboardingParameters";
import { Loader } from "../../../UI/Loaders/Loaders";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { postData } from "../../../newApis/apiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/icons/error-icon.svg";
import Toggler from "../../../NewComponents/Toggler/Toggler.component";
import useAggregatorEnvironment from "../../../custom-hooks/useAggregatorEnvironment";
import { useFilter } from "../../LoanEligibilityQuestions/EligibilityQuestions/useFilter";
import { appInsights } from "../../../config/appInsights";
import { onBoardingStatusCode } from "../../AggregatorOnboardingReview/util";
import { ROLE_TYPES } from "../../../helpers/roleTypes";
import { getBlobData } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";
import { RootState } from "../../../redux/store";
import ContactPerson from "./Tabs/ContactPerson";
import BusinessInformation from "./Tabs/BusinessInformation";
import BusinessOwnersInformation from "./Tabs/BusinessOwnerInformation";
import BusinessDocumentation from "./Tabs/BusinessDocumentation";
import RegisteredTrustees from "./Tabs/RegisteredTrustees";
import SeniorManagement from "./Tabs/SeniorManagement";
import Directors from "./Tabs/Directors";
import Shareholders from "./Tabs/Shareholders";
import Partners from "./Tabs/Partners";
import AuditTrail from "./Tabs/AuditTrail";
import { BusinessTypes, IBusinessDocument } from "../Interfaces";
import FunderPeopleDocumentsApprovalModal from "./Tabs/BusinessDocumentation/FunderPeopleDocumentsApprovalModal";
import AggregatorOnboardingReviewModal from "../../AggregatorOnboardingReview/AggregatorOnboardingReviewModal.component";
import BusinessOnboardingApprovalModal from "./Tabs/BusinessDocumentation/BusinessOnboardingDocumentsApproval";

import styles from "./businessOnboardingReview.module.scss";

const BusinessCustomerOnboardingReview = () => {
  const [activeTab, setActiveTab] = useState("contact-person");
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [filterOptions, handleChange, filterParam] = useFilter();
  const [documentOwnerType, setDocumentOwnerType] = useState(1);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [actionData, setActionData] = useState<{
    actionType: string;
    data: IBusinessDocument;
  }>();
  const [directorsActionData, setDirectorsActionData] = useState({});

  const dismissModalRef = useRef<HTMLButtonElement>(null);
  const match = useRouteMatch();
  const { id } = match.params as { id: number };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const approved = searchParams.get("approved");
  const declined = searchParams.get("declined");
  const history = useHistory();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Busines Onboarding Request",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: onboardingRequestDetails,
    status,
    error: onboardingParametersError,
    refetch,
  } = useAnAggregatorOnboardingParameters({
    aggregator_id: Number(id),
    productType: filterParam,
    documentOwnerType: Number(documentOwnerType),
  });

  const {
    data: environmentData,
    status: environmentStatus,
    error: environmentError,
  } = useAggregatorEnvironment(Number(id));

  useEffect(() => {
    if (activeTab === "legal-compliance") {
      setDocumentOwnerType(1);
    } else if (activeTab === "credit-risk") {
      setDocumentOwnerType(2);
    }
  }, [activeTab]);

  const declineRequest = async ({ message }: { message: string }) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const reqBody = {
      aggregator_id: Number(id),
      comment: message,
    };
    try {
      await postData(
        ajaxEndpoints.DECLINE_AGGREGATOR_ONBOARDING_REQUEST,
        reqBody
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccess("Onboarding request has been declined.");

      setTimeout(() => {
        history.push("/aggregator-onboarding");
      }, 2000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "AggregatorOnboardingReview.jsx" },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setError(errorHandler(error));
    }
  };

  if (status === "loading" || environmentStatus === "loading") {
    return <Loader centered={true} text="Loading onboarding information..." />;
  }
  if (onboardingParametersError || environmentError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            aggregator's onboarding information, please try again.
          </h4>
        </div>
      </div>
    );
  }

  const isDisableDeclineApprovalRequest = (_statusCode: number) => {
    if (
      _statusCode === onBoardingStatusCode.LEGAL_IN_PROGRESS &&
      loggedInUser?.roles.includes(ROLE_TYPES.LEGAL_ONBOARDING_APPROVER)
    ) {
      return false;
    } else return true;
  };

  const handleDownloadAggregatorOnboardingRequest = async () => {
    setIsDownloadingReport(true);
    try {
      const response = await getBlobData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_ONBOARDING_REPORT}?customer_id=${id}`
      );

      if (response?.status === 200) {
        let blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "aggregator_onboarding_report.pdf");
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: "Unable to download document. Contact admin",
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server error: Unable to download document. Contact admin",
          },
        })
      );
    }
    setIsDownloadingReport(false);
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Review Request
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {loggedInUser?.roles.includes(
            ROLE_TYPES.LEGAL_ONBOARDING_APPROVER
          ) && (
            <button
              className="btn advancly-green-btn btn-md mr-3"
              onClick={handleDownloadAggregatorOnboardingRequest}
            >
              {isDownloadingReport ? "Downloading..." : "Download"}
            </button>
          )}

          {declined && declined === "true" ? (
            <button className="btn advancly-red-btn btn-md" type="button">
              <span className="d-flex align-items-center">
                Declined{" "}
                <span className="color-red ml-2">
                  <ErrorIcon color="red" />
                </span>
              </span>
            </button>
          ) : approved && approved === "true" ? (
            <Toggler aggregatorId={id} />
          ) : (
            <div>
              <button
                className="btn advancly-red-btn btn-md mr-2"
                data-toggle="modal"
                data-target="#addOnboardingReviewModal"
                onClick={() => setModalActionType("decline")}
                // disabled={
                //   loading ||
                //   isDisableDeclineApprovalRequest(environmentData?.statusCode)
                // }
              >
                {loading && modalActionType === "decline"
                  ? "Declining..."
                  : "Decline"}
              </button>
              <button
                className="btn advancly-btn btn-md"
                data-toggle="modal"
                data-target="#addOnboardingReviewModal"
                onClick={() => setModalActionType("approve")}
                // disabled={
                //   loading ||
                //   isDisableDeclineApprovalRequest(environmentData?.statusCode)
                // }
              >
                {loading && modalActionType === "approve"
                  ? "Approving..."
                  : "Approve Request"}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Business Name</div>
          <div className={styles.content}>
            {onboardingRequestDetails?.businessInformation.biz_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>
            {onboardingRequestDetails.businessInformation?.biz_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Business Type</div>
          <div className={styles.content}>
            {onboardingRequestDetails?.businessInformation.business_type}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Date</div>
          <div className={styles.content}></div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3 mt-5">
        <ToggleTab
          text="Contact Person"
          id="contact-person"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Business Information"
          id="business-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.REGISTERED_BUSINESS && (
          <ToggleTab
            text="Business Owner Information"
            id="business-owners-information"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        <ToggleTab
          text="Business Documentation"
          id="business-documentation"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.LIMITED_LIABILITY && (
          <ToggleTab
            text="Directors"
            id="directors"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.NGO && (
          <ToggleTab
            text="Registered Trustees"
            id="registered-trustees"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.NGO ||
          (onboardingRequestDetails?.businessInformation?.business_type_id ===
            BusinessTypes.LIMITED_LIABILITY && (
            <ToggleTab
              text="Senior Management"
              id="senior-management"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))}

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.LIMITED_LIABILITY && (
          <ToggleTab
            text="Shareholders"
            id="shareholders"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {onboardingRequestDetails?.businessInformation?.business_type_id ===
          BusinessTypes.PARTNERSHIP && (
          <ToggleTab
            text="Partners"
            id="partners"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        <ToggleTab
          text="Audit Trail"
          id="audit-trail"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div className="pl-5 pr-5">
        {(() => {
          switch (activeTab) {
            case "contact-person":
              return (
                <ContactPerson
                  data={onboardingRequestDetails?.contactPerson!}
                />
              );

            case "business-information":
              return (
                <BusinessInformation
                  data={onboardingRequestDetails?.businessInformation!}
                />
              );

            case "business-owners-information":
              return (
                <BusinessOwnersInformation
                  data={onboardingRequestDetails?.businessOwnerInformation!}
                />
              );

            case "business-documentation":
              return (
                <BusinessDocumentation
                  data={onboardingRequestDetails?.businessDocumentation!}
                  fetchData={refetch}
                  setActionData={setActionData}
                />
              );

            case "registered-trustees":
              return (
                <RegisteredTrustees
                  data={onboardingRequestDetails?.registeredTrustees!}
                />
              );

            case "senior-management":
              return (
                <SeniorManagement
                  data={onboardingRequestDetails?.seniorManagement!}
                />
              );

            case "directors":
              return (
                <Directors
                  data={onboardingRequestDetails?.directorsDetails!}
                  funderId={id}
                  setActionData={setDirectorsActionData}
                />
              );

            case "shareholders":
              return (
                <Shareholders data={onboardingRequestDetails?.shareHolders!} />
              );

            case "partners":
              return (
                <Partners
                  data={onboardingRequestDetails?.partnersInformation!}
                />
              );

            case "audit-trail":
              return (
                <AuditTrail data={onboardingRequestDetails?.auditTrail!} />
              );

            default:
              return null;
          }
        })()}
      </div>

      <FunderPeopleDocumentsApprovalModal
        data={directorsActionData!}
        refetchData={refetch}
      />

      <AggregatorOnboardingReviewModal
        dismissModalRef={dismissModalRef}
        declineRequest={declineRequest}
        modalActionType={modalActionType}
        loading={loading}
        initialOnboardingCode={environmentData?.statusCode}
      />

      <BusinessOnboardingApprovalModal
        data={actionData}
        refetchData={refetch}
      />
    </Fragment>
  );
};

export default BusinessCustomerOnboardingReview;
